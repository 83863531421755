<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">Login to your account.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <!-- <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <!-- <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div> -->
              <!-- <b-spinner label="Spinning"></b-spinner> -->
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <!-- <b-form-checkbox v-model="model.rememberMe">Remember me</b-form-checkbox> -->
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4" :disabled="loggingIn" :loading="loggingIn">
                      Sign In
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <!-- <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col> -->
            <b-col class="text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        model: {
          email: '',
          password: '',
          strategy: "local",
          rememberMe: false
        },
        loggingIn: false
      };
    },
    methods: {
      async onSubmit() {
        // this will be called only after form is valid. You can do api call here to login
        const that = this;

        that.loggingIn = true;

        try {
          // Attempt a login
          const response = await that.$http.post("/authentication/", that.model);
          that.$store.commit('user/setToken', response.data.accessToken);
          window.localStorage.setItem('token', response.data.accessToken);
          // console.log(response.data.accessToken);

          const userResponse = await that.$http.get('/user/me/');
          // console.log(userResponse.data);
          that.$store.commit('user/setUser', userResponse.data);
          window.localStorage.setItem('user', JSON.stringify(userResponse.data));

          const customerResponse = await that.$http.get('/my-customer/me/');
          // console.log(customerResponse.data);
          that.$store.commit('user/setCustomer', customerResponse.data);
          window.localStorage.setItem('customer', JSON.stringify(customerResponse.data));

          // Navigate to next if set, otherwise, go to user page.
          if (that.$route.query.next) {
            that.$router.push({
              path: that.$route.query.next,
            });
          } else {
            that.$router.push({
              name: 'Dashboard'
            });
          }

          // that.$notify({
          //   text: "You have been logged in",
          //   duration: 10000,
          //   type: "success",
          // });

          that.loggingIn = false;
        } catch (error) {
          that.loggingIn = false;
        }
      }
    }
  };
</script>
